var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "countsheetgroupnew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("countsheetgroups.new.dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "fd-responsive-stepper" },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        "data-cy": "details",
                        complete: _vm.step > _vm.detailsStep,
                        step: _vm.detailsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("countsheetgroups.new.steps.details")) +
                          "\n          "
                      ),
                      _vm.detailserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "countsheetgroups.new.steps.details-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        "data-cy": "partselection",
                        complete: _vm.step > _vm.partSelectionStep,
                        step: _vm.partSelectionStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("countsheetgroups.new.steps.part-selection")
                          ) +
                          "\n          "
                      ),
                      _vm.partserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "countsheetgroups.new.steps.parts-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        "data-cy": "partordering",
                        complete: _vm.step > _vm.partOrderStep,
                        step: _vm.partOrderStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("countsheetgroups.new.steps.part-ordering")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.detailsStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                { staticClass: "px-2 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between pb-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required],
                                          autofocus: ""
                                        },
                                        model: {
                                          value: _vm.countSheetGroup.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.countSheetGroup,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "countSheetGroup.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled: _vm.processing,
                                          label: _vm.$t("common.description"),
                                          allowImages: false
                                        },
                                        model: {
                                          value:
                                            _vm.countSheetGroup.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.countSheetGroup,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "countSheetGroup.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.partSelectionStep }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "partsform", on: { submit: _vm.preventSubmit } },
                        [
                          _c("fd-count-sheet-group-parts-selection-form", {
                            attrs: { loading: _vm.processing },
                            on: {
                              "item:added": _vm.partSelected,
                              "item:removed": _vm.partDeselected
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.partOrderStep }
                    },
                    [
                      _c("v-data-table", {
                        directives: [
                          {
                            name: "fd-table-sortable",
                            rawName: "v-fd-table-sortable"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:publicID",
                            value: _vm.$t("common.code"),
                            expression: "$t('common.code')",
                            arg: "publicID"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:name",
                            arg: "name"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:description",
                            arg: "description"
                          }
                        ],
                        attrs: {
                          items: _vm.parts,
                          "items-per-page": 100,
                          "footer-props": {
                            "items-per-page-options": [5, 25, 50, 100, -1]
                          }
                        },
                        on: { "sort:end": _vm.dragEnded },
                        scopedSlots: _vm._u([
                          {
                            key: "item.fd-drag",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("far fa-grip-lines")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "xs-save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            "data-cy": "cancel",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled:
                              _vm.step == _vm.lastStep ||
                              _vm.processing ||
                              _vm.saving,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }